<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          label="Name(*)"
          color="#002441"
          placeholder="Name"
          v-model.number="payload.name"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Contact No"
          placeholder="Ex: 01750000000"
          color="#002441"
          v-model="payload.contact_number"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Amount(*)"
          v-model.number="payload.amount"
          placeholder="0"
          type="number"
          color="#002441"
        ></v-text-field>
      </v-col>
      <v-col>
        <div class="fileUpload">
          <span class="custom-para">Select Image</span>
          <input
            type="file"
            class="upload"
            ref="fileFromCash"
            v-on:change="handleFileUpload"
          />
        </div>
        <div class="pa-2" v-if="file_name">{{ file_name }}</div>
        <div class="pa-2" style="color: red" v-if="error">{{ error }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="payload.note"
          auto-grow
          filled
          color="#002441"
          label="Note"
          rows="4"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="#002441" elevation="0" @click="onSubmit"> Submit </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["deposite_type", "transaction_to_accounts"],
  data() {
    return {
      payload: {},
      file_name: "",
      error: "",
    };
  },
  methods: {
    // Validate file extension
    validateFile(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name) === false) return false;
      return true;
    },

    // Handle Image upload
    handleFileUpload() {
      // Validate file if it is an image
      let is_valid_file = this.validateFile(this.$refs.fileFromCash.files[0]);

      if (is_valid_file) {
        this.payload.image = this.$refs.fileFromCash.files[0];
        this.file_name = this.payload.image.name;
        this.error = "";
      } else {
        this.payload.image = "";
        this.file_name = "";
        this.error = "Please,upload an image.";
      }
    },

    // Submit the form
    onSubmit() {
      this.$emit("onSubmitForm", this.payload);

      this.payload = {
        cheque_issue_date: new Date().toISOString().substr(0, 10),
      };

      this.file_name = "";
      this.error = "";
    },
  },
};
</script>

<style>
</style>